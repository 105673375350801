"use client";
import { DateFilter } from "@/components/filters/date-filter";
import { CompetitorFilter } from "@/components/filters/competitor-filter";
import { SourceFilter } from "@/components/filters/source-filter";
import { LocationFilter } from "@/components/filters/location-filter";
import { FunctionalGroupFilter } from "@/components/filters/functional-group-filter";
import { Icons } from "@ui/components/icons";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@ui/components/ui/sheet";
import { Button } from "@ui/components/ui/button";
import { useAppStore } from "@/stores/useAppStore";
import { Alert, AlertTitle, AlertDescription } from "@ui/components/ui/alert";

export type FilterStateType = {
  selectedMonths?: string;
  selectedCompetitor?: string;
  selectedCategory?: string;
  selectedSeriesIndex?: number;
  showPercentage?: boolean;
  showProportion?: boolean;
  isExpanded?: boolean;
  selectedLocation?: string;
  selectedFunctionalGroup?: string;
  selectedSource?: string;
  selectedEmotion?: string;
  isFilterSubscribed?: boolean;
};

export type FilterOptionsType = {
  hasIndex?: boolean;
  hasDateFilter?: boolean;
  hasCompetitorFilter?: boolean;
  hasPercentageToggle?: boolean;
  categoryFilter?: string;
  hasLocationFilter?: boolean;
  hasFunctionalGroupFilter?: boolean;
  hasSourceFilter?: boolean;
};

export type FilterProps = {
  filterOptions?: FilterOptionsType;
};

const FilterBar = ({ filterOptions }: FilterProps) => {
  const loadingData = useAppStore((state) => state.loadingData);
  const {
    hasDateFilter = false,
    hasCompetitorFilter = false,
    hasIndex = false,
    hasFunctionalGroupFilter = false,
    hasLocationFilter = false,
    hasSourceFilter = false,
  } = filterOptions!;

  const info = loadingData
    ? { title: "Data refreshing!", description: "Analysing and building data" }
    : {
        title: "Data refreshed!",
        description: "Charts updated with new data",
      };
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline">
          <Icons.listFilter className="mr-2 h-4 w-4" />
          Filters
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Filter Chart data</SheetTitle>
          <SheetDescription>
            Select the filters options to change the chart data. This will
            filter data in all charts
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col items-justify gap-4 w-full space-y-2 pt-4">
          <DateFilter enabled={hasDateFilter}></DateFilter>
          <SourceFilter enabled={hasSourceFilter}></SourceFilter>
          <LocationFilter enabled={hasLocationFilter}></LocationFilter>
          <FunctionalGroupFilter
            enabled={hasFunctionalGroupFilter}
          ></FunctionalGroupFilter>
          <CompetitorFilter
            hasIndex={hasIndex}
            enabled={hasCompetitorFilter}
          ></CompetitorFilter>
        </div>
        <div className="mt-4">
          <Alert>
            {loadingData ? (
              <Icons.rotateCw className="mr-2 h-4 w-4 animate-spin stroke-warning" />
            ) : (
              <Icons.circleCheck className="mr-2 h-4 w-4 stroke-primary" />
            )}
            <AlertTitle>{info.title}</AlertTitle>
            <AlertDescription>{info.description}</AlertDescription>
          </Alert>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FilterBar;
