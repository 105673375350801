"use client";

import { useAppStore } from "@/stores/useAppStore";
import { FunctionalGroups, Locations } from "@/types/review";

interface FiltersStoreProps {
  locations: Locations[];
  functionalGroups: FunctionalGroups[];
}

export function LocationFunctionalFilterStoreInitializer({
  locations,
  functionalGroups,
}: FiltersStoreProps) {
  const { initializedLocationFunctionalData } = useAppStore.getState();
  if (!initializedLocationFunctionalData) {
    useAppStore.setState({
      locations,
      functionalGroups,
      initializedLocationFunctionalData: true,
    });
  }
  return null;
}
